import map_options from '~vc@/art_work_select_location/map_options'
const loadGoogleMapsApi = require('load-google-maps-api')

class Map {
  constructor(mapElement, locations, geoList, currentZoom, myCenter){
    // console.log('cc currentZoom', currentZoom)
    this.mapElement = mapElement
    this.locations = locations
    this.geoList = geoList
    this.currentZoom = currentZoom || 5
    if(_.isEmpty(myCenter)){
      this.myCenter =  {lat: 25.017, lng: 121.366}
    } else {
      this.myCenter = JSON.parse(myCenter)
    }
    this.NeedSubmit = false
  }

  setGoeList(newGeoList){
    this.NeedSubmit = this.geoList != newGeoList || newGeoList.length === 1
    this.geoList = newGeoList.join(',')
    document.getElementById("geo_locaion_query").value = this.geoList;
  }

  setZoomNumber(newZoom){
    this.currentZoom = newZoom
    document.getElementById("geo_zoom_size").value = newZoom;
  }

  setCenter(newCenter){
    this.myCenter = newCenter
    document.getElementById("geo_center").value = JSON.stringify(newCenter);
  }

  loadGoogleMapsApi() {
    return loadGoogleMapsApi({ key: window.google_api });
  }

  async createMap(currentZoom) {
    let self = this
    // let defaultLat = 25.017
    // let defaultLng = 121.366
    // if(self.locations.length != 0){
    //   let moveCenter = self.locations[0]
    //   defaultLat = moveCenter.lat
    //   defaultLng = moveCenter.lng
    // }
    let thisMapOptions = map_options
    thisMapOptions.zoom = +self.currentZoom || +currentZoom
    thisMapOptions.center = this.myCenter
    thisMapOptions.maxZoom = 18
    thisMapOptions.mapId = "16ca5ed86e2268dc"

    // Import the library
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

    var map = new google.maps.Map(self.mapElement, thisMapOptions);

    // Create an array of alphabetical characters used to label the markers.
    var labels = '';

    // Add some markers to the map.
    // Note: The code uses the JavaScript Array.prototype.map() method to
    // create an array of markers based on a given "locations" array.
    // The map() method here has nothing to do with the Google Maps API.
    var markercollection = [];
    
    var markers = self.locations.map(function(location, i) {
      let mpoint = new AdvancedMarkerElement({
        map: map,
        position: location,
        title: labels[i % labels.length]
      });

      mpoint.gid = location.gid;
      mpoint.art_work_id = location.id;
      // click event for single point
      mpoint.addListener('click', async function() {
        let zoomMapSize = mpoint.map.getZoom();
        //let myCenter = {lat: mpoint.getMap().getCenter().lat(), lng: mpoint.getMap().getCenter().lng()}
        await self.setZoomNumber(currentZoom)
        await self.setGoeList([mpoint.gid])
        await mpoint.map.setZoom(zoomMapSize);
      });
      markercollection.push(mpoint);
      return mpoint;
    });
    
    const customRenderer = {      
      render(_ref, stats, map) {
        let {
          count,
          position
        } = _ref;
        // change color if this cluster has more markers than the mean cluster
        const color = count > Math.max(10, stats.clusters.markers.mean) ? "#FCBE05" : "#4AAFF3";
        // create svg literal with fill color
        const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
<circle cx="120" cy="120" opacity=".6" r="70" />
<circle cx="120" cy="120" opacity=".3" r="90" />
<circle cx="120" cy="120" opacity=".2" r="110" />
<text x="50%" y="50%" style="fill:#000" text-anchor="middle" font-size="50" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
</svg>`;
        const title = `Cluster of ${count} markers`,
          // adjust zIndex to be above other markers
          zIndex = Number(google.maps.Marker.MAX_ZINDEX) + count;
        if (markerClusterer.MarkerUtils.isAdvancedMarkerAvailable(map)) {
          // create cluster SVG element
          const parser = new DOMParser();
          const svgEl = parser.parseFromString(svg, "image/svg+xml").documentElement;
          svgEl.setAttribute("transform", "translate(0 25)");
          const clusterOptions = {
            map,
            position,
            zIndex,
            title,
            content: svgEl
          };
          return new google.maps.marker.AdvancedMarkerElement(clusterOptions);
        }
        const clusterOptions = {
          position,
          zIndex,
          title,
          icon: {
            url: `data:image/svg+xml;base64,${btoa(svg)}`,
            anchor: new google.maps.Point(25, 25)
          }
        };
        return new google.maps.Marker(clusterOptions);
      },
  };
  markerClusterer.DefaultRenderer = customRenderer
    // Add a marker clusterer to manage the markers.
    // <script src="https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js"></script>
    var markerCluster = new markerClusterer.MarkerClusterer({markers, map, renderer: customRenderer});
    // click event for cluster point
    markerCluster.addListener('clusterclick', function(cluster){
      let mk = cluster.getMarkers();
      // this is because some lng & lat are the same, but they are diffenent geo record. cause of some data import issue
      let res = _.chain(mk).map(k => `${k.position.lat()}${k.position.lng()}`).uniq().value();
      if(res.length === 1) {
        // collect geo ids
        let newGeoList = _.chain(mk).map(k => k.gid).uniq().value();
        self.setGoeList(newGeoList);
      }
    })

    map.addListener('zoom_changed', async function() {
      let currentZoom = map.getZoom()
      let myCenter = {lat: map.getCenter().lat(), lng: map.getCenter().lng()}
      await self.setZoomNumber(currentZoom)
      await self.setCenter(myCenter)
      await self.getMyRecords()
    })
  }

  getMyRecords(){
    if(this.NeedSubmit){
      setTimeout($("#geo_locaion_qsubmit").click(), 1000);
    }
  }
}

document.addEventListener("DOMContentLoaded", function() {
  let locations = JSON.parse(document.getElementById("map_data__payload").value)
  let geoList = document.getElementById("geo_locaion_query").value
  let currentZoom = document.getElementById("geo_zoom_size").value
  let geo_center = document.getElementById("geo_center").value
  let mapElement = document.getElementById('map')
  locations = _.filter(locations, (lo) => {
    if(lo.lat != null && lo.lng != null){
      return true
    }else{
      console.warn("map point geo info error:", lo)
      return false
    }
  })
  let newMap = new Map(mapElement, locations, geoList, currentZoom, geo_center)
  newMap.loadGoogleMapsApi().then(function() {
    newMap.createMap(currentZoom);
  });

  // add checkbox change jquery
  $("input[type='checkbox']").change(function(){
    let targetLabel = $(`label[for="${this.id}"]`)
    if(this.checked){
      targetLabel.addClass("home__category-label--actived")
    } else {
      targetLabel.removeClass("home__category-label--actived")
    }
    $("#search_button").click()
  });
  // add clean map filter event
  $("#clean_map_filter").click(function(){
    $("#geo_zoom_size").val(+currentZoom - 1)
    $("#geo_locaion_query").val('')
    setTimeout($("#geo_locaion_qsubmit").click(), 1000);
  })

  // count scroll heihgt
  let checkWidth = $(".section-search-section").width()
  let element1 = $(".section-search-section").height()
  let element2 = $(".map-section").innerHeight()
  let elementNiv = 0
  if(checkWidth <= 700) {
    elementNiv = -50
  } else if (checkWidth <= 1080){
    elementNiv = -30
  } else if(checkWidth <= 1080) {
    elementNiv = 200
  } else if(checkWidth <= 1980) {
    elementNiv = 300
  }
  // do scroll if mappoint is not null
  if(!_.isEmpty(geoList)){
    $('html, body').animate({
      scrollTop: element1 + element2 - elementNiv,
    }, 2000);
  }

  // listen change event trigger search
  $('#trigger_search_section').click(() => {
    const basicY = '-100%'
    const mMoveY = 677
    if($('#m-search_bar').css('display') !== 'none'){
      $('#m-search_bar').css('display', 'none')
      $('.desktop-fixed-header').css('display', 'block')
    } else {
      $('.desktop-fixed-header').css('display', 'none')
      $('#m-search_bar').css('display', 'block')
    }
    $('html,body').animate({
      scrollTop: 0
    }, 700);
  })
  // for change selected color
  const search_bar_st = document.getElementById("m-search_bar");
  if(search_bar_st.clientWidth > 900){
    if($('select[name="year_search"]').val() !== 'all'){
      $('select[name="year_search"]').css('color', 'black')
    }
    if($('select[name="area_search"]').val() !== 'all'){
      $('select[name="area_search"]').css('color', 'black')
    }
  }
  // auto submit filter
  $('select[name="year_search"]').change(() => {
    $('#search_button').click()
  })
  $('select[name="area_search"]').change(() => {
    $('#search_button').click()
  })
  $('select[name="artwork_order"]').change((val) => {
    const selectedVal = $('select[name="artwork_order"]').find(":selected").val()
    // set hidden value of post form
    $('input[name="artwork_order"]').val(selectedVal)
    $('#search_button').click()
  })
  $('#search_bar_icon').click(()=>{
    $('#search_button').click()
  })
})
